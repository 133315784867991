import React, { useState } from 'react'
import * as styles from './WeatherUpdates.module.scss'
import { TRANSLATIONS } from '../../misc/translations'
import Arrow from '../../images/svg-components/Arrow.js'
import Swimmer from '../../images/svg-components/Swimmer.js'
import Cloud from '../../images/svg-components/Cloud.js'
import Sun from '../../images/svg-components/Sun.js'
import is from 'date-fns/locale/is'
import format from 'date-fns/format'
import { Link } from '../'
import classNames from 'classnames'

const fullDay = 3600 * 24 * 1000
const FROM = new Date()
FROM.setHours(0, 0, 0, 0)
const TO = new Date(FROM.getTime() + (7 * fullDay))

const formatWaveheight = waveheight => {
  return waveheight ? waveheight + 'm' : '-'
}

const formatWindSpeed = windSpeed => {
  return windSpeed ? windSpeed + 'm/s' : '-'
}

const formatTempature = tempature => {
  return tempature ? tempature + '°' : '-'
}

const getRows = weatherUpdateData => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const now = today.getTime()

  const rows = weatherUpdateData
    .map(item => {
      const [date, time] = item.date.split(' ')
      const [day, month, year] = date.split('.')
      const [hour, min] = time.split(':')

      const dateObj = new Date(year, (month - 1), day, hour, min)
      const hours = parseInt(format(dateObj, 'HH'), 0)
      const dateOnly = new Date(year, (month - 1), day)
      const timestamp = dateObj.getTime()
      const isToday = dateOnly.getTime() === now

      return {
        ...item,
        formattedDate: format(dateObj, 'd. MMM', { locale: is }),
        dateObj,
        dateOnly,
        hours,
        isToday,
        timestamp
      }
    })
    .filter(item => (
      item.dateObj >= FROM &&
      item.dateObj <= TO &&
      (item.isToday || item.hours === 12)
    ))

  const [, closestTimeToday] = rows
    .filter(x => x.isToday)
    .reduce((acc, cur) => {
      const [closest] = acc
      const diff = Math.abs(now - cur.timestamp)

      if (diff < closest) {
        return [diff, cur]
      }

      return acc
    }, [Infinity, null])

  return [
    ...(closestTimeToday ? [closestTimeToday] : []),
    ...rows.filter(x => !x.isToday)
  ]
}

const getDefault = weatherUpdateData => {
  return weatherUpdateData[0].date
}

const WeatherUpdates = ({
  weatherUpdateData,
  currentLang,
  isLoading
}) => {
  if (isLoading || weatherUpdateData.length <= 0) {
    return null
  }

  const vegagerdingLink = 'https://www.sjolag.is/dufl/landeyjahofn-v/'
  const getByTimestamp = (ts, weatherUpdateData) => {
    return weatherUpdateData.find(row => row.date === ts)
  }

  const data = getRows(weatherUpdateData)
  const defaultTime = getDefault(data)
  const [selectedTimestamp, setSelectedTimestamp] = useState(defaultTime)
  const selectedEntry = getByTimestamp(selectedTimestamp, data) || {}

  const {
    waveheight,
    tempature,
    windSpeed
  } = selectedEntry

  return (
    <div className='container notification-container mt-4'>
      <div className={classNames(styles.container, 'col-md-6 px-3 px-md-4')}>
        <div className='d-flex justify-content-between'>
          <div className='stats-large'>
            <div className='mr-2' >
              <div className='d-flex align-items-center'>
                <Swimmer />
                <div className='hdln--3 pl-2'>
                  {TRANSLATIONS[currentLang].WAVEHEIGHT}
                </div>
              </div>
            </div>
            {formatWaveheight(waveheight)}
          </div>
          <div className='px-3 stats-large'>
            <div className='d-flex'>
              <Cloud />
              <div className='hdln--3 pl-2'>{TRANSLATIONS[currentLang].WINDSPEED}</div>
            </div>
            {formatWindSpeed(windSpeed)}
          </div>
          <div className='px-3 d-none d-sm-inline stats-large'>
            <div className='d-flex'>
              <Sun />
              <div className='hdln--3 pl-2'>{TRANSLATIONS[currentLang].TEMPERATURE}</div>
            </div>
            {formatTempature(tempature)}
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          {data
            .map((row, index) => {
              const timeClasses = classNames(
                styles.time,
                'mx-0',
                { [styles.selected]: row.date === selectedTimestamp }
              )

              const clickHandler = () => setSelectedTimestamp(row.date)

              return (
                <div
                  key={index}
                  onKeyDown={clickHandler}
                  role='button'
                  tabIndex='-1'
                  className={timeClasses}
                  onClick={clickHandler}>
                  {row.isToday && TRANSLATIONS[currentLang].TODAY}
                  {!row.isToday && row.formattedDate}
                </div>
              )
            })}
        </div>
      </div>
      <Link to={vegagerdingLink}>
        <span className='hdln--3 lh--26 pl-4 ml-3 m-md-0 pl-md-0'>
          {TRANSLATIONS[currentLang].SEETHEROADCONSTRUCTIONFORECAST} <Arrow />
        </span>
      </Link>
    </div >
  )
}

export default WeatherUpdates
