import React from 'react'
import * as styles from './Video.module.scss'
import ReactPlayer from 'react-player'

const Video = ({ title, videoUrl }) => {
  return (
    <section className='container mt-100'>
      {title &&
        <h1 className='hdln--1'>{title}</h1>
      }
      <div className={styles.container}>
        <ReactPlayer
          url={videoUrl}
          width='100%'
          height='100%'
          config={{ vimeo: {
            playerOptions: {
              controls: true
            }
          } }}
        />
      </div>
    </section>
  )
}

export default Video
